/* eslint-disable quotes */
/* istanbul ignore file */

const SUBGOAL_NAME_TEXT = 'Subgoal name'

const translations = {
  en: {
    map: {
      types: {
        project: 'Project Map',
        experience: 'Experience Map',
        process: 'Process Map',
        other: 'Other Map'
      },
      actions: {
        create: 'Create a Map',
        delete: 'Delete Map',
        viewCard: 'View Map Card',
        convert: 'Convert to Map',
        useSampleMap: 'Start with a simple map',
        goBack: 'Back to Maps',
        unlink: 'Unlink from Map'
      },
      errors: {
        nameRequired: 'Map name is required',
        lockedJourney: "{{user}}'s editing. Map locked" // This should receive a variable
      },
      history: {
        removedAll: 'Removed all maps'
      },
      texts: {
        title: 'Map',
        titlePlural: 'Maps',
        label: 'map',
        sampleMapTitle: 'Sample Map',
        metaTitle: '{{name}} Map',
        dot: {
          readOnlyPlaceholder: SUBGOAL_NAME_TEXT,
          placeholder: SUBGOAL_NAME_TEXT
        },
        goal: {
          readOnlyPlaceholder: 'Goal name',
          placeholder: 'Goal name'
        },
        dashboard: {
          emptyList:
            'A Map visualises the subgoals a customer takes to achieve a goal, starting from identifying the need and continuing through the process of finding and implementing a solution.'
        },
        sidebar: {
          tabs: {
            goals: {
              description: 'Start creating your map by adding a goal\nand its first subgoal on the board'
            },
            people: {
              description: 'Empathise with your customer by associating people with the map'
            }
          }
        },
        card: {
          title: 'Map',
          cardTitle: 'Map Card',
          addMap: 'Add Map',
          view: 'View Map Card'
        },
        modal: {
          people: {
            description: 'Add or remove personas related to this map',
            entireMapSelected: 'The Entire Map'
          },
          edit: {
            title: 'Map Card'
          }
        },
        form: {
          createMapMetaTitle: 'Create New Map',
          createMapMetaDescription: 'Add a new Customer Map to your library',
          title: 'Create your Map',
          subtitle: "Let's get it started!",
          name: 'Map Name',
          description: 'Description',
          peopleDescription: 'Please select the people associated to this map',
          submit: 'Create Map'
        }
      }
    },
    goals: {
      actions: {
        viewCard: 'View Goal Card',
        add: 'Add Goal',
        create: 'Convert to Goal',
        unlink: 'Unlink from Goal'
      },
      errors: {},
      history: {},
      texts: {
        title: 'Goal',
        titlePlural: 'Goals',
        label: 'goal',
        unnamedGoal: 'Unnamed Goal',
        untitledGoal: 'Untitled goal',
        defaultDisplayName: 'this goal',
        goalsOverviewTitle: 'Goals Overview',
        goalNamePlaceholder: 'Goal name',
        cardTitle: 'Goal Card',
        card: {
          title: 'Goal Card',
          emptyIssuesList: 'Analyse your maps and start adding\ttasks to your subgoals',
          buttons: {
            addTag: 'Add Tags',
            addAttachment: 'Add Attachments',
            addExternalLink: 'Add External Links'
          }
        },
        modal: {
          convert: {
            title: 'Convert',
            subtitle: 'Convert Goal to Map',
            description: 'convert this goal to a new map',
            confirmation: 'Yes, Convert it'
          }
        }
      }
    },
    subgoals: {
      actions: {
        viewCard: 'View Subgoal Card',
        add: 'Add Subgoal',
        addBranch: 'Add Branch',
        addLink: 'Add Link Subgoal',
        end: 'End Subgoal',
        remove: 'Remove Subgoal',
        removeOne: 'Remove this subgoal only',
        removeThisAndSubsequent: 'Remove this subgoal and all following subgoals',
        removalSubtext: 'Please select what you want to remove.',
        confirmRemoval: 'Yes, remove it',
        unlink: 'Unlink from Subgoal'
      },
      errors: {},
      history: {},
      texts: {
        title: 'Subgoal',
        titlePlural: 'Subgoals',
        label: 'subgoal',
        cardTitle: 'Subgoal Card',
        untitledSubgoal: 'Untitled subgoal',
        displayName: 'this Subgoal',
        typeName: SUBGOAL_NAME_TEXT,
        readOnlyNamePlaceholder: SUBGOAL_NAME_TEXT,
        namePlaceholder: SUBGOAL_NAME_TEXT
      }
    },
    people: {
      actions: {
        create: 'Create',
        add: 'Add Persona',
        duplicate: {
          title: 'Duplicate Persona',
          subtitle: 'What information would you like to duplicate:',
          submit: 'Yes, duplicate it',
          allSelectedText: 'All the information'
        },
        manage: 'Manage personas',
        view: 'View Persona',
        addGoal: 'Add goal',
        addMotivation: 'Add motivation',
        addFrustration: 'Add frustration',
        back: 'Back to Personas'
      },
      errors: {
        addPeople: 'Please add personas',
        emptyGoal: 'Please add a goal to the persona you are creating.',
        emptyMotivation: 'Please add a motivation to the persona you are creating.',
        emptyFrustrations: 'Please add a frustration to the persona you are creating.',
        emptyExternalLinks: 'Add here any useful information related to this persona',
        emptyDescription: 'Please add a description to the persona you are creating.'
      },
      history: {},
      texts: {
        title: 'Persona',
        titlePlural: 'Personas',
        label: 'persona',
        labelPlural: 'personas',
        emptyListTitle: 'customer persona',
        emptyListSubtitle: 'persona',
        metaDescription: 'Explore your persona library',
        recent: 'Recent Persona',
        dashboard: {
          emptyList:
            'A Persona is a model of the ideal target audience based on their critical characteristics. It helps create a high-level view of the primary stakeholders you are solving problems for.'
        },
        card: {
          linkedMaps: 'Linked Maps'
        },
        modal: {
          maps: 'Maps'
        },
        form: {
          title: 'Create a new Persona',
          description: 'Add a new Persona to your library',
          editTitle: 'Edit {{peopleName}} Persona',
          editDescription: 'Edit your Persona {{peopleName}}',
          goalsTitle: 'Goals',
          goalsDescription: 'Desired outcomes that this persona wants to achieve when using your product or service',
          motivationsTitle: 'Motivations',
          frustrationsTitle: 'Frustrations',
          frustrationsDescription:
            'Frustrations are difficulties faced by this persona when trying to achieve their goals.',
          createMap: {
            title: 'Create a map for this persona.',
            button: 'Create Map'
          },
          submit: 'Save'
        }
      }
    },
    tasks: {
      actions: {
        create: 'Create',
        add: 'Add Task',
        link: 'Link Task',
        card: {
          addMaps: 'Add Maps'
        },
        viewCard: 'View Task Card',
        linkExisting: 'Link Existing {{title}}'
      },
      errors: {
        emptyList: 'Please add a task',
        noTasksCaptured: 'No tasks have been captured yet'
      },
      history: {},
      texts: {
        title: 'Task',
        titlePlural: 'Tasks',
        tasksBoardTitle: 'Board View',
        taskListTitle: 'List View',
        label: 'issue',
        showClosedTasks: 'Show Closed Tasks',
        hideClosedTasks: 'Hide Closed Tasks',
        recent: 'Recent Tasks',
        card: {
          emptyList: 'Analyse your maps and start \ncapturing tasks'
        },
        table: {
          dropdown: {
            title: 'Tasks Types'
          },
          export: {
            selectedTasks: 'Selected tasks only'
          }
        }
      },
      duplicate: {
        title: 'Duplicate Task',
        subtitle: 'What information would you like to include?',
        submit: 'Yes, duplicate it',
        duplicating: 'Duplicating...',
        loading: 'Loading...',
        allSelectedText: 'All the information',
        taskTitle: 'Task Title',
        taskLocationPrompt: 'Where should this task be created?',
        taskLocation: 'Task Location',
        buttonAdd: 'Add',
        buttonCancel: 'Cancel'
      }
    },
    tooltip: {
      actions: {
        create: 'Create {{option}}'
      },
      texts: {
        mapOption: 'map',
        peopleOption: 'people',
        taskOption: 'task',
        linkTasks: 'Link Tasks',
        addTasks: 'Add Tasks'
      }
    },
    errors: {
      actions: { navigateToHome: 'Back to Home', confirm: 'Ok', login: 'Log In' },
      texts: {
        notFound: {
          message: 'The page you are looking for doesn’t exist.',
          messageDetails:
            'Well, this is awkward. It looks like the page you’re searching for has taken a permanent vacation. Let’s get you back on track!'
        },
        serviceUnavailable: {
          message: 'An unexpected error occurred, please visit us again later',
          messageDetails:
            'Something unexpected has happened, and our service is temporarily unavailable. Rest assured we are working on restoring as soon as feasible. Please come back later. We apologize for any inconvenience.'
        },
        sessionTimeout: {
          message: 'Session timeout, please log in.',
          messageDetails: 'Your session has expired due to inactivity. Please log back in to resume your work. '
        }
      }
    },
    search: {
      texts: {
        searchByTitlePlaceholder: 'Search by title',
        findByTitlePlaceholder: 'Find {{title}}'
      }
    },
    cards: {
      texts: {
        tag: {
          addTagsTitle: 'Add {{tagName}}',
          findAndCreateTags: 'Find or create tags',
          createTag: 'Create tag',
          addNewTags: 'Add "{{newTagName}}"'
        }
      },
      actions: {
        addTags: 'Add Tags',
        addAttachments: 'Add Attachments',
        addExternalLinks: 'Add External Links'
      }
    },
    prioritization: {
      texts: { title: 'Prioritization' }
    },
    buttons: {
      actions: {
        cancelButton: 'Cancel',
        saveButton: 'Save',
        editButton: 'Edit',
        duplicateButton: 'Duplicate',
        deleteButton: 'Delete'
      }
    }
  }
}

// Handmade util to get translations, we can delete this once we decided on a proper tool/library.
const t = (key, parameters = {}) => {
  const keys = key.split('.')
  let translation = translations['en']

  for (let k of keys) {
    if (translation[k] === undefined) {
      return key // Return the key as a fallback if the translation is not found
    } else {
      translation = translation[k]
    }
  }

  if (typeof translation === 'string') {
    // Replace parameters in the translation string
    for (const parameter of Object.keys(parameters)) {
      const regex = new RegExp(`{{${parameter}}}`, 'g')
      translation = translation.replace(regex, parameters[parameter])
    }
  }

  return translation
}

export default t
